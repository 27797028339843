/* eslint-disable react/prop-types */

import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import Register from 'components/pages/service-post/register';
import Button from 'components/shared/button';
import ContactForm from 'components/shared/contact-form';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import ArrowIcon from 'icons/arrow.inline.svg';
import MainLayout from 'layouts/main';

const TableWrapper = ({ children }) => (
  <div className="max-w-2xl overflow-x-scroll md:max-w-none">
    <table>{children}</table>
  </div>
);
const components = {
  table: TableWrapper,
  ContactForm,
};

const ServicePost = (props) => {
  const {
    data: {
      mdx: {
        html,
        frontmatter: { title, description, category, duration, price, speakers },
      },
    },
  } = props;

  const pageMetadata = {
    title,
  };

  const isTrainingPage = category === 'Training';
  return (
    <MainLayout pageMetadata={pageMetadata}>
      <Container
        className="pt-8 pb-14 sm:pt-10 sm:pb-20 lg:pt-16 lg:pb-30 xl:pt-28 xl:pb-40"
        size="md"
      >
        <span className="page-label">{category}</span>
        <Heading className="mt-3.5 max-w-[800px] sm:mt-4" theme="black" tag="h1">
          {title}
        </Heading>
        <Heading
          className="mt-4 max-w-[800px] leading-tight sm:mt-5 xl:mt-7"
          theme="black"
          tag="h2"
          size="md"
        >
          {description}
        </Heading>
        <article className="flex flex-col-reverse lg:flex-row">
          <div className="prose-table prose mt-5 max-w-[800px] lg:prose-xl xl:mt-7">
            <MDXProvider components={components}>
              <MDXRenderer>{html}</MDXRenderer>
            </MDXProvider>
          </div>
          {isTrainingPage && (
            <Register
              className="mt-6 shrink-0 self-start lg:ml-20 lg:-mt-9 lg:max-w-[244px] xl:-mt-14 xl:max-w-[280px]"
              duration={duration}
              price={price}
              speakers={speakers}
            />
          )}
        </article>
        <ContactForm className="mt-8" id="register" />
        {isTrainingPage && (
          <Button className="mt-10 items-center" theme="tertiary" color="outline" to="/trainings">
            <ArrowIcon className="mr-2 rotate-180" />
            <span>Back to trainings</span>
          </Button>
        )}
      </Container>
    </MainLayout>
  );
};

export default ServicePost;
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      html: body
      frontmatter {
        title
        description
        category
        price
        duration
        speakers
      }
    }
  }
`;
