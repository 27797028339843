import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';

import andreasGehrig from './images/andreas-gehrig.jpg';
import nemanjaKostic from './images/nemanja-kostic.jpg';

const images = {
  'Andreas Gehrig': andreasGehrig,
  'Nemanja Kostic': nemanjaKostic,
};

const Register = ({ className, duration, price, speakers }) => (
  <aside
    className={classNames(
      className,
      'flex w-full flex-row flex-wrap items-start border border-gray-1 p-4.5 md:flex-nowrap md:space-y-0 md:space-x-14 lg:flex-col lg:space-x-0 lg:space-y-5'
    )}
  >
    <div className="flex flex-1 flex-col md:flex-initial">
      <span className="text-xs font-semibold uppercase leading-none tracking-widest text-gray-4">
        Duration
      </span>
      <span className="mt-2 text-lg leading-[1.125]">{duration}</span>
    </div>
    <div className="flex flex-1 flex-col md:flex-initial">
      <span className="text-xs font-semibold uppercase leading-none tracking-widest text-gray-4">
        Price
      </span>
      <span className="mt-2 text-lg leading-[1.125]">{price}</span>
    </div>
    <div className="mt-6 flex w-full flex-col md:mt-0 md:w-max">
      <span className="text-xs font-semibold uppercase leading-none tracking-widest text-gray-4">
        Speakers
      </span>
      <div className="mt-3 space-y-3">
        {speakers?.map((speaker, index) => {
          const image = images[speaker];
          return (
            <div className="flex items-center" key={index}>
              <img
                className="mr-3 h-7 w-7 rounded-full"
                width={28}
                height={28}
                src={image}
                alt={speaker}
              />
              <span className="text-lg leading-[1.125]">{speaker}</span>
            </div>
          );
        })}
      </div>
    </div>
    <Button
      className="mt-7 w-full justify-center md:!ml-auto md:mt-0 md:w-auto md:self-center lg:ml-0 lg:w-full"
      theme="tertiary"
      color="primary"
      to="#register"
      onClick={(e) => {
        e.preventDefault();
        document.querySelector('#register').scrollIntoView({ behavior: 'smooth' });
      }}
    >
      Register
    </Button>
  </aside>
);

Register.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  speakers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Register.defaultProps = {
  className: null,
};

export default Register;
